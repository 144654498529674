import React, {useState} from 'react';
import {Panel, PanelHeader, Group, RichCell, Div, Header, SimpleCell, Separator, Text, Accordion, Subhead} from '@vkontakte/vkui';
import donateImage from '../donate.png';
import gameImage from '../game.png'
import helpfulImage from '../helpful.png'
import marriageImage from '../marriage.png'
import roleplayImage from '../roleplay.png'
import thanksImage from '../thanks.png'
import chatManager from '../chat_manager.png'
import {Icon24CrownOutline, Icon24DonateOutline, Icon24Dropdown,} from '@vkontakte/icons';

const Donate = () => {
    const [expanded, setExpanded] = useState(false);
    const handleChange = () => {
        setExpanded(!expanded);
    };
    return(
        <Panel id="donate">
            <PanelHeader>Информация</PanelHeader>
            <Group style={{marginBottom: "30px"}} header={<Header mode="secondary">Информация</Header>}>
                <Div>
                    <img src={donateImage} alt="Donate" style={{width: '100%', borderRadius: 8}}/>
                    <RichCell multiline>
                        <Header mode="primary">VIP статус</Header>
                        <SimpleCell indicator="299₽" before={<Icon24CrownOutline/>}>1 месяц</SimpleCell>
                        <SimpleCell indicator="720₽" before={<Icon24CrownOutline/>}>3 месяца</SimpleCell>
                        <SimpleCell indicator="1260₽" before={<Icon24CrownOutline/>}>6 месяцев</SimpleCell>
                        <SimpleCell indicator="2700₽" before={<Icon24CrownOutline/>}>Навсегда</SimpleCell>
                        <Separator/>
                        <Accordion>
                            <Accordion.Summary icon={<Icon24Dropdown/>} onClick={handleChange}>Преимущества</Accordion.Summary>
                            <Accordion.Content>
                                <Subhead>
                                    <Text>💍 Золотой брак во всех беседах<br/></Text>
                                    <Text>☘️ Автогороскоп на каждый день, подробнее: .автогороскоп<br/></Text>
                                    <Text>💍 Использование VIP РП команд в браке<br/></Text>
                                    <Text>🍪 Дополнительная попытка в .аз коп<br/></Text>
                                    <Text>🍪 Бонус 50 тыс. печенек в .аз бонус<br/></Text>
                                    <Text>🍪 Задержка на бонус всего 10 минут<br/></Text>
                                    <Text>🍪 Переводы печенек без комиссии<br/></Text>
                                    <Text>🍪 Возможность взлома бриллиантового сейфа<br/></Text>
                                    <Text>🍪 Откат на взлом сейфа 2 часа<br/></Text>
                                    <Text>🍪 Откат на полеты в космос 2 часа<br/></Text>
                                    <Text>🍪 Местонахождение всех локаций исследования за 2 часа<br/></Text>
                                    <Text>🍪 Время для погружения подлодки сокращается на 50%<br/></Text>
                                    <Text>🍪 Зарплата в дуэлях x10<br/></Text>
                                    <Text>🍪 За победу в дуэлях вы получаете x15<br/></Text>
                                    <Text>🍪 Лимит на кол-во ферм: 60<br/></Text>
                                    <Text>🍪 Лимит на кол-во бизнесов: 60<br/></Text>
                                    <Text>📢 Просмотр закрытых профилей и балансов<br/></Text>
                                    <Text>📢 Установка настроек приватности<br/></Text>
                                    <Text>📢 Максимальная длинна никнейма 35 символов<br/></Text>
                                    <Text>🍪 Возможность получить 1 пончик в одних из бонусах<br/></Text>
                                    <Text>🍪 При неудачном взломе сейфа, суд может оправдать вас обнулив откат<br/></Text>
                                    <Text>🍪 В фортуне есть возможность выпадания шкатулок, схемы хакера<br/></Text>
                                    <Text>🍪 При неудачном ограблении - 1 день отката независимо от исхода<br/></Text>
                                    <Text>💍 Если Вы выйдите с беседы ваш брак не будет аннулирован<br/></Text>
                                    <Text>🍪 Время полёта ракеты сокращено на 60%<br/></Text>
                                    <Text>🍪 Поиск наживок для рыбалки раз в 3 часа<br/></Text>
                                    <Text>🍪 Колесо фортуны доступно раз в 30 минут<br/></Text>
                                    <Text>🍪 При получении прибыли с предприятий вы не платите налоги<br/></Text>
                                    <Text>🍪 Бонус в русской рулетке в размере 30 очков<br/></Text>
                                    <Text>🍪 Бонус в покере теперь 30 тыс. фишек и 70 респектов<br/></Text>
                                    <Text>🍪 Бизнес будет приносить доход раз в 8 часов<br/></Text>
                                    <Text>🍪 Кокосовая ферма будет приносить доход раз в 8 часов<br/></Text>
                                    <Text>🌋 Доступ к установке фонов для цитат<br/></Text>
                                    <Text>🌋 Лимит на создание рп-команд: 1000<br/></Text>
                                    <Text>🍪 Создание собственных промокодов .аз спромо<br/></Text>
                                    <Text>🍪 Специальная иконка в .аз профиль<br/></Text>
                                    <Text>🍪 Активация VIP промокодов<br/></Text>
                                    <Text>🤙 Персональная поддержка через .поддержка<br/></Text>
                                    <Text>🤖 Никаких задержек при использовании команды .gpt<br/></Text>
                                    <Text>🤖 Моментальное выполнение команды .img<br/></Text>
                                    <Text>💍 Особая картинка в твоем .брак профиль<br/></Text>
                                    <Text>🍪 Каждую неделю ты получишь золотые промокоды<br/></Text>
                                    <Text>📢 Дополнительные РП-действия<br/></Text>
                                    <Text>📢 Беседа будет получать дополнительный рейтинг<br/></Text>
                                    <Text>🍪 Возвращай +1% при проигрыше в некоторых играх через .аз<br/></Text>
                                    <Text>🤖 Пользуйся командой .ai для генерации картинок, включая 18+</Text>
                                </Subhead>
                            </Accordion.Content>
                        </Accordion>
                        <Separator style={{marginBottom: "20px"}}/>
                        <Header mode="primary">Premium беседа</Header>
                        <SimpleCell indicator="99₽" before={<Icon24DonateOutline/>}>1 месяц</SimpleCell>
                        <SimpleCell indicator="240₽" before={<Icon24DonateOutline/>}>3 месяца</SimpleCell>
                        <SimpleCell indicator="420₽" before={<Icon24DonateOutline/>}>6 месяцев</SimpleCell>
                        <SimpleCell indicator="900₽" before={<Icon24DonateOutline/>}>Навсегда</SimpleCell>
                        <Separator/>
                        <Accordion>
                            <Accordion.Summary icon={<Icon24Dropdown/>} onClick={handleChange}>Преимущества</Accordion.Summary>
                            <Accordion.Content>
                                <Subhead>
                                    <Header mode="secondary">👑 Преимущества Premium беседы:</Header>
                                    <Div>
                                        <Text>» Использование Premium RolePlay команд<br/>
                                            » В Ваш чат не будет приходить рекламы<br/>
                                            » Дополнительные баллы в рейтинге чатов<br/>
                                            » Приоритетная выдача в рейтинге чатов<br/>
                                            » Приоритетная поддержка<br/>
                                            » Список преимуществ будет пополняться/изменяться</Text>
                                    </Div>
                                </Subhead>
                            </Accordion.Content>
                        </Accordion>
                        <Separator style={{marginBottom: "20px"}}/>
                        <Accordion>
                            <Accordion.Summary icon={<Icon24Dropdown/>} onClick={handleChange}>Виртуальная
                                валюта</Accordion.Summary>
                            <Accordion.Content>
                                <Subhead>
                                    <Text>
                                        Игровую валюту можно купить, если не хотите долго ждать.<br/>
                                        Курс покупки пока что такой:<br/>
                                        1 000 000 000 печенек = 100₽<br/>
                                        Купить можно, открыв меню командой .меню в личных сообщениях с ботом, и выбрать
                                        соответствующий раздел
                                    </Text>
                                </Subhead>
                            </Accordion.Content>
                        </Accordion>
                        <Accordion>
                            <Accordion.Summary icon={<Icon24Dropdown/>}
                                               onClick={() => handleChange(1)}>Другое</Accordion.Summary>
                            <Accordion.Content>
                                <Div>
                                    <Text>
                                        <Header>Любой каприз за Ваши деньги</Header>
                                        То, что мы уже делали:<br/><br/>
                                        » Восстановление брака - 100₽<br/>
                                        » Вайп профиля - 100₽<br/>
                                        » Уникальный аватар в .аз профиль - 200₽<br/>
                                        » Уникальный фон в .аз профиль - 200₽<br/>
                                        » Уникальный никнейм в .ник - 500₽
                                    </Text>
                                </Div>
                            </Accordion.Content>
                        </Accordion>
                        <Separator/>
                    </RichCell>
                    <a href="http://grizzly-wiki.ru/commands#67VV" rel="noreferrer" target="_blank">
                        <img src={chatManager} alt="Chat Manager" style={{width: '100%', borderRadius: 8}}/>
                    </a>
                    <a href="http://grizzly-wiki.ru/commands#2wVC" rel="noreferrer" target="_blank">
                        <img src={gameImage} alt="Game" style={{width: '100%', borderRadius: 8}}/>
                    </a>
                    <a href="http://grizzly-wiki.ru/commands#WB7u" rel="noreferrer" target="_blank">
                        <img src={marriageImage} alt="Marriage" style={{width: '100%', borderRadius: 8}}/>
                    </a>
                    <a href="http://grizzly-wiki.ru/commands#WVlB" rel="noreferrer" target="_blank">
                        <img src={roleplayImage} alt="Roleplay" style={{width: '100%', borderRadius: 8}}/>
                    </a>
                    <a href="http://grizzly-wiki.ru/commands#3oES" rel="noreferrer" target="_blank">
                        <img src={thanksImage} alt="Thanks" style={{width: '100%', borderRadius: 8}}/>
                    </a>
                    <a href="http://grizzly-wiki.ru/commands#7mNx" rel="noreferrer" target="_blank">
                        <img src={helpfulImage} alt="FAQ" style={{width: '100%', borderRadius: 8}}/>
                    </a>
                    <a href="http://grizzly-wiki.ru/commands#67VV" rel="noreferrer" target="_blank">
                        <img src={chatManager} alt="Donate" style={{width: '100%', borderRadius: 8}}/>
                    </a>
                </Div>

            </Group>
        </Panel>
    );
};
export default Donate;