import React, { useState } from 'react';
import { Panel, PanelHeader, Group, Div, Header, Card, Separator, SimpleCell, Accordion } from '@vkontakte/vkui';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import {
    Icon24GiftOutline,
    Icon24MoneyCircleOutline,
    Icon24MoneyCircle,
    Icon24LikeOutline,
    Icon24SmileOutline,
    Icon24UserOutline,
    Icon24StarsOutline,
    Icon24QuestionOutline,
    Icon24LaptopOutline,
    Icon24HomeOutline,
    Icon24BriefcaseOutline,
    Icon24Globe,
    Icon24CarOutline,
    Icon24PlaneOutline,
    Icon24FlashOutline,
    Icon24StorefrontOutline,
    Icon24HammerOutline,
    Icon24Locate,
    Icon24MoreHorizontalOverlay,
    Icon24CameraOutline,
    Icon24Dropdown, Icon24MenuOutline
} from '@vkontakte/icons';
import { parseISO, format } from 'date-fns';

const loaderStyle = css`
    display: block;
    margin: 0 auto;
`;

const infoStyle = { color: 'var(--vkui--color_text_subhead)' };

const Profile = ({ playerInfo, formatNumber, loading }) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
        setExpanded(!expanded);
    };

    return (
        <Panel id="profile">
            <PanelHeader>Игровой профиль</PanelHeader>
            {loading ? (
                <Div>
                    <center>
                        <ClipLoader css={loaderStyle} size={150} color={"#123abc"} loading={loading} />
                    </center>
                </Div>
            ) : (
                <Card style={{ marginBottom: 16, borderRadius: 8 }}>
                    <Group header={<Header mode="secondary">Статистика Вашего профиля</Header>}>
                        <Div>
                            <Separator />
                            <SimpleCell indicator={playerInfo.promocodes} before={<Icon24GiftOutline />}>Промокодов</SimpleCell>
                            <SimpleCell indicator={formatNumber(playerInfo.cookies)} before={<Icon24MoneyCircleOutline />}>Печенек</SimpleCell>
                            <SimpleCell indicator={formatNumber(playerInfo.bank_cookies)} before={<Icon24MoneyCircle />}>В банке</SimpleCell>
                            <SimpleCell indicator={playerInfo.coconut} before={<Icon24LikeOutline />}>Кокосов</SimpleCell>
                            <SimpleCell indicator={playerInfo.donuts} before={<Icon24SmileOutline />}>Пончиков</SimpleCell>
                            <SimpleCell indicator={`${playerInfo.win_count}/${playerInfo.defeat_count} (${(playerInfo.defeat_count > 0 ? (playerInfo.win_count / playerInfo.defeat_count).toFixed(2) : 0)})`} before={<Icon24UserOutline />}>Побед/Поражений</SimpleCell>
                            <SimpleCell indicator={playerInfo.scheme} before={<Icon24LaptopOutline />}>Схем хакера</SimpleCell>
                            <SimpleCell indicator={playerInfo.registered ? format(parseISO(playerInfo.registered), 'yyyy-MM-dd') : ''} before={<Icon24Globe />}>Регистрация</SimpleCell>
                            <Separator />

                            <Accordion>
                                <Accordion.Summary icon={<Icon24Dropdown />} onClick={handleChange}>
                                    Подробнее
                                </Accordion.Summary>
                                <Accordion.Content>
                                    <Div style={infoStyle}>
                                        <SimpleCell indicator={playerInfo.stars} before={<Icon24StarsOutline />}>Звезд</SimpleCell>
                                        <SimpleCell indicator={playerInfo.questions_count} before={<Icon24QuestionOutline />}>Вопросов</SimpleCell>
                                        <SimpleCell indicator={playerInfo.cat_count} before={<Icon24HomeOutline />}>Котов</SimpleCell>
                                        <SimpleCell indicator={playerInfo.ugadajka_count} before={<Icon24BriefcaseOutline />}>Угадаек</SimpleCell>
                                        <Separator />
                                        <SimpleCell indicator={playerInfo.car_id} before={<Icon24CarOutline />}>Автомобили</SimpleCell>
                                        <SimpleCell indicator={playerInfo.plane_id} before={<Icon24PlaneOutline />}>Самолеты</SimpleCell>
                                        <SimpleCell indicator={playerInfo.helicopter_id} before={<Icon24FlashOutline />}>Вертолеты</SimpleCell>
                                        <SimpleCell indicator={playerInfo.yacht_id} before={<Icon24HammerOutline />}>Яхты</SimpleCell>
                                        <SimpleCell indicator={playerInfo.clothes_id} before={<Icon24Locate />}>Одежда</SimpleCell>
                                        <SimpleCell indicator={playerInfo.pet_id} before={<Icon24HomeOutline />}>Питомцы</SimpleCell>
                                        <SimpleCell indicator={playerInfo.business_count} before={<Icon24StorefrontOutline />}>Бизнесы</SimpleCell>
                                        <SimpleCell indicator={playerInfo.safe_hacked} before={<Icon24MoreHorizontalOverlay />}>Взлом сейфов</SimpleCell>
                                        <SimpleCell indicator={playerInfo.submarine_type} before={<Icon24FlashOutline />}>Тип подлодки</SimpleCell>
                                        <SimpleCell indicator={playerInfo.defuse_collected} before={<Icon24MoreHorizontalOverlay />}>Разминирований</SimpleCell>
                                        <SimpleCell indicator={playerInfo.fish_data ? playerInfo.fish_data.exp : 'Нет данных'} before={<Icon24MenuOutline />}>Опыт рыболова</SimpleCell>
                                        <SimpleCell indicator={playerInfo.contribution_rate} before={<Icon24MoneyCircleOutline />}>Вклад</SimpleCell>
                                        <SimpleCell indicator={playerInfo.rocket_exp} before={<Icon24MenuOutline />}>Опыт ракеты</SimpleCell>
                                        <SimpleCell indicator={playerInfo.choice_avatar} before={<Icon24CameraOutline />}>Выбранный аватар</SimpleCell>
                                        <SimpleCell indicator={playerInfo.vip_frame} before={<Icon24MoneyCircleOutline />}>VIP рамка</SimpleCell>
                                    </Div>
                                </Accordion.Content>
                            </Accordion>
                        </Div>
                    </Group>
                </Card>
            )}
        </Panel>
    );
};

export default Profile;
