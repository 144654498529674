import React, { useState } from 'react';
import '@vkontakte/vkui/dist/vkui.css';
import { AdaptivityProvider, AppRoot, View, Tabbar, TabbarItem, SplitLayout, SplitCol } from '@vkontakte/vkui';
import { Icon28UserOutline, Icon28ChatsOutline, Icon28FavoriteOutline, Icon24FireAltOutline } from '@vkontakte/icons';
import Profile from './pages/Profile';
import Chats from './pages/Chats';
import Marriages from './pages/Marriages';
import Donate from './pages/Donate';
import TelegramLoginButton from 'react-telegram-login';

function App() {
    const [activePanel, setActivePanel] = useState('profile');
    const [playerInfo, setPlayerInfo] = useState({
        user_id: null,
        bank_cookies: 0,
        coconut: 0,
        donuts: 0,
        promocodes: 0,
        stars: 0,
        questions_count: 0,
        cat_count: 0,
        cookies: 0,
        ugadajka_count: 0,
        mat_count: 0,
        win_count: 0,
        defeat_count: 0,
        scheme: 0,
        house: 'Нет',
        job: 'Нет',
        business: 'Нет',
        business_count: 0,
        registered: '',
        days_since_registration: 0,
    });
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const handleTelegramResponse = (response) => {
        setUser(response);
        fetch(`/api/get-player-info?user_id=${response.id}`)
            .then(response => response.json())
            .then(data => {
                setPlayerInfo(data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Ошибка при получении данных игрока:', err);
                setLoading(false);
            });
    };

    const formatNumber = (number) => {
        return number !== undefined ? number.toLocaleString() : '0';
    };

    return (
        <AdaptivityProvider>
            <AppRoot>
                {!user ? (
                    <TelegramLoginButton botName="GRIZZLY BOT" dataOnauth={handleTelegramResponse} />
                ) : (
                    <SplitLayout>
                        <SplitCol>
                            <View activePanel={activePanel}>
                                <Profile id="profile" playerInfo={playerInfo} formatNumber={formatNumber} loading={loading} />
                                <Chats id="chats" user={user} />
                                <Marriages id="marriages" user={user} />
                                <Donate id="donate" />
                            </View>
                            <Tabbar>
                                <TabbarItem
                                    onClick={() => setActivePanel('profile')}
                                    selected={activePanel === 'profile'}
                                    text="Профиль"
                                ><Icon28UserOutline /></TabbarItem>
                                <TabbarItem
                                    onClick={() => setActivePanel('chats')}
                                    selected={activePanel === 'chats'}
                                    text="Чаты"
                                ><Icon28ChatsOutline /></TabbarItem>
                                <TabbarItem
                                    onClick={() => setActivePanel('marriages')}
                                    selected={activePanel === 'marriages'}
                                    text="Браки"
                                ><Icon28FavoriteOutline /></TabbarItem>
                                <TabbarItem
                                    onClick={() => setActivePanel('donate')}
                                    selected={activePanel === 'donate'}
                                    text="Инфо"
                                ><Icon24FireAltOutline /></TabbarItem>
                            </Tabbar>
                        </SplitCol>
                    </SplitLayout>
                )}
            </AppRoot>
        </AdaptivityProvider>
    );
}

export default App;
