import React, { useEffect, useState } from 'react';
import {Group, Card, CardGrid, Div, RichCell, Separator, Avatar, PanelHeader} from '@vkontakte/vkui';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const loaderStyle = css`
    display: block;
    margin: 0 auto;
`;

const getRoleLabel = (role) => {
    switch (role) {
        case 0: return 'Участник';
        case 1: return 'Модератор';
        case 2: return 'Администратор';
        case 3: return 'Владелец';
        default: return 'Неизвестно';
    }
};

function Chats({ user }) {
    const [chats, setChats] = useState([]);
    const [chatTitles, setChatTitles] = useState({});
    const [chatAvatars, setChatAvatars] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user) {
            fetch(`/api/get-chats/${user.id}`)
                .then(response => response.json())
                .then(data => {
                    setChats(data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error('Ошибка при получении данных чатов:', err);
                    setLoading(false);
                });

            fetch('/api/get-chat-titles')
                .then(response => response.json())
                .then(data => {
                    const titles = {};
                    data.forEach(chat => titles[chat.telegram_id] = chat.title);
                    setChatTitles(titles);
                })
                .catch(err => console.error('Ошибка при получении названий чатов:', err));
        }
    }, [user]);

    useEffect(() => {
        if (chats.length > 0) {
            const chatIds = chats.map(chat => chat.chat_telegram_id);
            fetch(`/api/get-chat-avatars/${chatIds.join(',')}`)
                .then(response => response.json())
                .then(data => setChatAvatars(data))
                .catch(err => console.error('Ошибка при получении аватаров чатов:', err));
        }
    }, [chats]);

    return (
        <Group header={<PanelHeader>Ваши чаты</PanelHeader>}>
            {loading ? (
                <Div>
                    <center>
                        <ClipLoader css={loaderStyle} size={150} color={"#123abc"} loading={loading} />
                    </center>
                </Div>
            ) : (
                <CardGrid size="l" style={{marginTop: "10px", marginBottom: "40px"}}>
                    <TransitionGroup>
                        {chats.map(chat => (
                            <CSSTransition key={chat.id} timeout={500} classNames="fade">
                                <Card style={{ marginBottom: 16, borderRadius: 8, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                                    <Div>
                                        <RichCell
                                            before={<Avatar size={40} src={chatAvatars[chat.chat_telegram_id] || 'https://via.placeholder.com/40'} />}
                                            multiline
                                        >
                                            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                {chatTitles[chat.chat_telegram_id] || 'Неизвестный чат'}
                                            </div>
                                            <div style={{ color: '#888' }}>
                                                Роль: {getRoleLabel(chat.role)}
                                            </div>
                                            <Separator />
                                            <div>
                                                Последнее сообщение: {format(new Date(chat.last_message_date), 'dd MMM yyyy', { locale: ru })}
                                            </div>
                                        </RichCell>
                                    </Div>
                                </Card>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </CardGrid>
            )}
        </Group>
    );
}

export default Chats;
