import React, { useEffect, useState } from 'react';
import { Group, Card, Div, RichCell, Separator, Avatar, PanelHeader, SimpleCell } from '@vkontakte/vkui';
import { format, parseISO } from 'date-fns';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
    Icon24BluetoothOutline,
    Icon24BoxingGloveOutline,
    Icon24FullscreenExit,
    Icon24GhostOutline,
    Icon24Globe,
    Icon24HandOutline,
    Icon24HideOutline,
    Icon24HomeHeartOutline,
    Icon24MenuOutline,
    Icon24SmileAddOutline,
    Icon24SmileOutline
} from '@vkontakte/icons';

const loaderStyle = css`
    display: block;
    margin: 0 auto;
`;

function Marriages({ user }) {
    const [marriages, setMarriages] = useState([]);
    const [avatars, setAvatars] = useState({});
    const [nicknames, setNicknames] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user) {
            fetch(`/api/get-marriages/${user.id}`)
                .then(response => response.json())
                .then(data => {
                    setMarriages(data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error('Ошибка при получении данных о браках:', err);
                    setLoading(false);
                });

            fetch('/api/get-nicknames')
                .then(response => response.json())
                .then(data => {
                    const nicknamesMap = {};
                    data.forEach(user => {
                        nicknamesMap[user.telegram_id] = user.nickname;
                    });
                    setNicknames(nicknamesMap);
                })
                .catch(err => console.error('Ошибка при получении данных никнеймов:', err));
        }
    }, [user]);

    useEffect(() => {
        if (marriages.length > 0) {
            const userIds = new Set();
            marriages.forEach(marriage => {
                marriage.user_id.forEach(id => userIds.add(id));
            });
            fetch(`/api/get-avatars/${[...userIds].join(',')}`)
                .then(response => response.json())
                .then(data => setAvatars(data))
                .catch(err => console.error('Ошибка при получении аватаров:', err));
        }
    }, [marriages]);

    return (
        <Group header={<PanelHeader>Ваши браки</PanelHeader>}>
            {loading ? (
                <Div>
                    <center>
                        <ClipLoader css={loaderStyle} size={150} color={"#123abc"} loading={loading} />
                    </center>
                </Div>
            ) : (
                <TransitionGroup>
                    {marriages.map(marriage => {
                        const {
                            user_id,
                            statistics,
                            condom,
                            registered
                        } = marriage;

                        const actions = {
                            hits: statistics[0] || 0,
                            kisses: statistics[1] || 0,
                            hugs: statistics[2] || 0,
                            bites: statistics[3] || 0,
                            slaps: statistics[4] || 0,
                            mischiefs: statistics[5] || 0,
                            licks: statistics[6] || 0,
                            blowjobs: statistics[7] || 0
                        };

                        const totalActions = Object.values(actions).reduce((a, b) => a + b, 0);
                        const partnerId = user_id.find(id => id !== user.id);
                        const partnerNickname = nicknames[partnerId] || 'Неизвестно';

                        return (
                            <CSSTransition key={marriage.id} timeout={500} classNames="fade">
                                <Card style={{ marginBottom: 16, borderRadius: 8, marginTop: 10 }}>
                                    <Div>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 16 }}>
                                            {user_id.map(id => (
                                                <Avatar key={id} size={64} src={avatars[id] || 'https://via.placeholder.com/40'} style={{ margin: '0 10px' }} />
                                            ))}
                                        </div>
                                        <RichCell
                                            caption="Партнёр"
                                            before={<Avatar size={48} src={avatars[partnerId] || 'https://via.placeholder.com/40'} />}
                                        >
                                            {partnerNickname}
                                        </RichCell>
                                        <SimpleCell indicator={condom ? 'да' : 'нет'} before={<Icon24HomeHeartOutline />}>Беременность</SimpleCell>
                                        <Separator />
                                        <SimpleCell indicator={actions.hits} before={<Icon24BoxingGloveOutline />}>Ударов</SimpleCell>
                                        <SimpleCell indicator={actions.kisses} before={<Icon24SmileAddOutline />}>Поцелуев</SimpleCell>
                                        <SimpleCell indicator={actions.hugs} before={<Icon24FullscreenExit />}>Обнимашек</SimpleCell>
                                        <SimpleCell indicator={actions.bites} before={<Icon24GhostOutline />}>Укусов</SimpleCell>
                                        <SimpleCell indicator={actions.slaps} before={<Icon24HandOutline />}>Шлепков</SimpleCell>
                                        <SimpleCell indicator={actions.mischiefs} before={<Icon24SmileOutline />}>Шалостей</SimpleCell>
                                        <SimpleCell indicator={actions.licks} before={<Icon24BluetoothOutline />}>Отлизов</SimpleCell>
                                        <SimpleCell indicator={actions.blowjobs} before={<Icon24HideOutline />}>Отсосов</SimpleCell>
                                        <Separator />
                                        <SimpleCell indicator={totalActions} before={<Icon24MenuOutline />}>Всего действий</SimpleCell>
                                        <SimpleCell indicator={format(parseISO(registered), 'yyyy-MM-dd')} before={<Icon24Globe />}>Регистрация</SimpleCell>
                                        <Separator />
                                    </Div>
                                </Card>
                            </CSSTransition>
                        );
                    })}
                </TransitionGroup>
            )}
        </Group>
    );
}

export default Marriages;
